.section {
  width: 100%;
  max-width: var(--average-width);
  margin-top: 50px;
}
.stripe {
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--micro-title);
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/* ------------------------- Table head ------------------------------- */

.table .head {
  width: 100%;
  display: flex;
  padding-bottom: 20px;
}

.table .head h5 {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--micro-title);
  font-family: "BuyanRegular";
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 100;
  font-size: calc(var(--base-em) * var(--ms0));
}

.table .head .flex_col:nth-child(1) {
  padding-left: 1.5em;
  width: 30%;
}

.table .head .flex_col:nth-child(2) {
  width: 17.5%;
}

.table .head .flex_col:nth-child(3) {
  width: 17.5%;
}

.table .head .flex_col:nth-child(4) {
  width: 25%;
}
.table .head .flex_col:nth-child(5) {
  width: 10%;
  padding-right: 1.5em;
  text-align: right;
}

/* ------------------------- Table items --------------------------------- */
.row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  padding: 2em 0 2em 0;
}

.table .items li {
  transition: var(--animation-smooth);
}

.table .items .flex_col:nth-child(1) {
  padding-left: 1.5em;
  width: 30%;
  padding-right: 20px;
}

.table .items .flex_col:nth-child(2) {
  width: 17.5%;
  padding-top: 0.25em;
  padding-right: 20px;
}

.table .items .flex_col:nth-child(3) {
  width: 17.5%;
  padding-top: 0.25em;
  padding-right: 20px;
}

.table .items .flex_col:nth-child(4) {
  width: 25%;
  padding-right: 20px;
}
.table .items .flex_col:nth-child(5) {
  width: 10%;
  padding-top: 0.25em;
  padding-left: 20px;
  padding-right: 1.5em;
  text-align: right;
}

.table .items h4 {
  position: relative;
  display: block;
  overflow: hidden;
  line-height: 1;
  transition: var(--animation-fast);
  transform: translateX(0);
}

.table .items h4 span {
  position: relative;
  display: block;
  padding: 0.1em 0;
}

.table .items a:hover h4 {
  transform: translateX(-.25em);
}

.table .items a p {
  transition: var(--animation-fast);
  transform: translateX(0em);
}

.table .items a:hover p {
  transform: translateX(.25em);
}

.table .items li a {
  transition: var(--animation-fast);
}

.table .items:hover a {
  opacity: 1;
}

.table .items a:hover h4,
.table .items a:hover p {
  color: var(--accent);
}

/* ------------------------- Hide table ------------------------------ */

@media screen and (max-width: 1024px) {
  .table {
    display: none;
  }
}

/* ------------------------- Grid ------------------------------- */

.grid {
  padding-top: 0;
}

.grid ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.grid ul li {
  display: block;
  width: 50%;
  position: relative;
  overflow: hidden;
  height: 0;
  width: 0;
  transform: translateY(0%) rotate(0.001deg) scale(1);
  transition: opacity 0.3s ease-in 0.4s,
    transform 1s cubic-bezier(0.16, 1, 0.3, 1);
}

.grid ul li.visible {
  height: auto;
  width: 50%;
}

.grid ul li.grid_fade_out {
  opacity: 0;
  transform: translateY(5em) rotate(0.001deg);
  transition: opacity 0.25s ease-out, transform 0s linear 0.3s;
}

.grid ul li.grid_fade_in {
  opacity: 1;
  transform: translateY(5em) rotate(0.001deg) scale(1);
}

.grid ul li .a {
  align-items: center;
  color: unset;
  text-decoration: unset;
  overflow: hidden;
  width: 100%;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.grid ul li .a .flex_col:nth-child(1) {
  width: 100%;
  margin-bottom: 15px;
}

.grid ul li .a .flex_col:nth-child(2) {
  width: 100%;
  margin-bottom: 20px;
}

.grid ul li .a .stripe {
  margin-top: 15px;
}

.grid ul li .a .flex_col:nth-child(3) {
  width: 70%;
}

.grid ul li .a .flex_col:nth-child(4) {
  width: 30%;
  text-align: right;
}

.grid ul li .a .tile_image {
  width: 100%;
  position: relative;
  background: var(--background-container);
  overflow: hidden;
}

.grid ul li .a .tile_image .overlay_image {
  transform: scale(1) rotate(0.001deg);
  transition: var(--animation-primary);
  will-change: transform;
}

.grid ul li .a:hover .tile_image .overlay_image {
  transform: scale(1.025) rotate(0.001deg);
}

.grid ul li .a .tile_image::before {
  display: block;
  content: "";
  padding-top: 100%;
}

.grid ul li .a h4 {
  position: relative;
  display: block;
  overflow: hidden;
  line-height: 1;
  transition: var(--animation-fast);
  transform: translateX(0) rotate(0.001deg);
  margin: 0;
}

.grid ul li .a h4 span {
  position: relative;
  display: block;
  padding: 0.1em 0;
}

@media screen and (max-width: 1024px) {
  .grid ul li .a h4 {
    font-size: calc(clamp(1.75em, 2.3vw, 2.5em) * 1.125);
  }
}

@media screen and (max-width: 620px) {
  .grid ul li.visible {
    width: 100%;
  }
}

/* ------------------------- Work Grid - Fade -------------------------------------------------- */

.grid_fade {
  height: 0;
  overflow: hidden;
  transform: translateY(0%) rotate(0.001deg) scale(1);
  transition: opacity 0.3s ease-in 0.4s,
    transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  padding: 0;
}

@media screen and (max-width: 1024px) {
  .grid_fade {
    height: auto;
  }
}
