@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@font-face {
  font-family: "BuyanThin";
  src: url(./public/fonts/BuyanThin.ttf) format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "BuyanRegular";
  src: url(./public/fonts/BuyanRegular.ttf) format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "BuyanBold";
  src: url(./public/fonts/BuyanBold.ttf) format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "Onest";
  font-weight: 100;
  src: local("Onest"), url(./public/fonts/Onest-Thin.woff) format("woff");
}
@font-face {
  font-family: "Onest";
  font-weight: 200;
  src: local("Onest"), url(./public/fonts/Onest-Light.woff) format("woff");
}
@font-face {
  font-family: "Onest";
  font-weight: 300;
  src: local("Onest"), url(./public/fonts/Onest-Regular.woff) format("woff");
}
@font-face {
  font-family: "Onest";
  font-weight: 400;
  src: local("Onest"), url(./public/fonts/Onest-Medium.woff) format("woff");
}
@font-face {
  font-family: "Onest";
  font-weight: 500;
  src: local("Onest"), url(./public/fonts/Onest-Bold.woff) format("woff");
}
@font-face {
  font-family: "Onest";
  font-weight: 600;
  src: local("Onest"), url(./public/fonts/Onest-ExtraBold.woff) format("woff");
}
@font-face {
  font-family: "Onest";
  font-weight: 700;
  src: local("Onest"), url(./public/fonts/Onest-Black.woff) format("woff");
}
:root {
  --ratio: 1.2;
  --base: 1.15;
  --base-em: calc(var(--base) * 1em);
  --base-px: calc(var(--base) * 16px);
  --ms0: 1;
  --ms1: var(--ratio); /* 1.2   */
  --ms2: calc(var(--ratio) * var(--ms1)); /* 1.44  */
  --ms3: calc(var(--ratio) * var(--ms2)); /* 1.728 */
  --ms4: calc(var(--ratio) * var(--ms3)); /* 2.074 */
  --ms5: calc(var(--ratio) * var(--ms4)); /* 2.488 */
  --ms6: calc(var(--ratio) * var(--ms5)); /* 2.986 */
  --ms7: calc(var(--ratio) * var(--ms6)); /* 3.583 */
  --background-main: #181818;
  --background-container: #232323;
  --primary: #ffffff;
  --accent: #78dfda;
  --title: #e6e6e6;
  --micro-title: #908e8e;
  --second-text: #b1b1b1;
  --card-text: #e6e6e6;
  --ssau: #2772c6;

  /* --background-main: #E6E6E6;
  --background-container: #F5F5F5;
  --primary: #181818;
    --accent: #67c8c3;
  --title: #232323;
  --micro-title: #434343;
  --second-text: #323232;
  --card-text: #e6e6e6;
  --ssau: #2772c6; */

  /* #05cbc1 #81D8D0*/
  
  --maximal-width: 100%;
  --average-width: 1280px;

  --animation-primary: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
  --animation-fast: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
  --animation-smooth: all 0.7s cubic-bezier(0.7, 0, 0.3, 1);
  --animation-slow: all 0.9s cubic-bezier(0.7, 0, 0.3, 1);
}

::selection {
  background: var(--accent);
}
::-moz-selection {
  background: var(--accent);
}

main {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  min-height: 100vh;
}
#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: 100vw;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  background-color: var(--background-main);
}
p {
  color: var(--second-text);
  font-family: "Onest";
  font-weight: 200;
  line-height: 150%;
  font-size: calc(var(--base-em) * var(--ms0));
}
h1 {
  color: var(--primary);
  font-family: "BuyanBold";
  text-transform: uppercase;
  font-weight: 100;
  font-size: calc(var(--base-em) * var(--ms6));
}
h2 {
  color: var(--primary);
  font-family: "BuyanRegular";
  text-transform: uppercase;
  font-weight: 100;
  font-size: calc(var(--base-em) * var(--ms5));
}
h3 {
  color: var(--primary);
  font-family: "BuyanRegular";
  text-transform: uppercase;
  font-weight: 100;
  font-size: calc(var(--base-em) * var(--ms4));
}
h4 {
  color: var(--primary);
  font-family: "BuyanRegular";
  text-transform: uppercase;
  font-weight: 100;
  font-size: calc(var(--base-em) * var(--ms3));
}
h5 {
  color: var(--primary);
  font-family: "BuyanRegular";
  text-transform: uppercase;
  font-weight: 100;
  font-size: calc(var(--base-em) * var(--ms2));
}
h6 {
  color: var(--primary);
  font-family: "BuyanRegular";
  text-transform: uppercase;
  font-weight: 100;
  font-size: calc(var(--base-em) / var(--ms1));
}
button {
  cursor: pointer;
  width: max-content;
  display: flex;
  padding: 8px 30px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #181818;
  font-size: calc(var(--base-em) * var(--ms0));
  text-align: center;
  font-family: "Onest";
  font-weight: 200;
  transition: all 0.3s;
  gap: 5px;
}
button:hover {
  background-color: var(--accent);
  border: 1px solid transparent;
  padding-left: 29px;
  padding-right: 31px;
}
button .svg {
  fill: #181818;
  transform: translateX(0);
  transition: all 0.3s;
}
button:hover .svg {
  transform: translateX(2px);
}

a {
  font-size: calc(var(--base-em) / var(--ms1));
  text-decoration: none;
  color: inherit;
}
a:link {
  text-decoration: none;
  color: inherit;
}
a:visited {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}
a:active {
  text-decoration: none;
  color: inherit;
}
.paragraph {
  margin-top: 20px;
  font-size: calc(var(--base-em) * var(--ms0));
}

.github_button {
  display: flex;
  flex-direction: row;
  width: max-content;
  align-items: center;
  gap: 10px;
  background-color: #24292d;
  padding: 10px;
  margin-top: 30px;
}
.github_button svg {
  fill: #e6e6e6;
}
.github_button p {
  color: #e6e6e6;
}


.privacy-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--accent);
  z-index: 2;
}
