footer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  z-index: -1;
   /* ??? */
  /* background-color: rgb(13, 13, 13); */
}
.footer {
  width: 100%;
  max-width: var(--average-width);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-content: center;
  padding: 1.5em;
}
.first_column {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--average-width);
  flex-wrap: wrap;
  gap: 25px;
}
.first_column_title {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  height: min-content;
  flex-direction: row;
  gap: 20px;
}
.first_column_title p {
  display: flex;
  color: var(--title);
  font-family: "BuyanRegular";
  text-transform: uppercase;
  font-size: calc(var(--base-em) * var(--ms6) * 1.2);
  line-height: 100%;
  padding-top: 10px;
}
.first_column_title img {
  width: 80px;
  height: 80px;
}
.first_column_buttons {
  display: flex;
  align-items: center;
  height: min-content;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 15px;
}
.button {
  cursor: pointer;
  display: flex;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 2px solid var(--title);
  transition: all 0.3s;
  font-size: calc(var(--base-em) / var(--ms0));
}
.button:hover {
  background-color: var(--accent);
}
.button p {
  color: var(--title);
  text-align: center;
  font-family: "Onest";
  font-weight: 200;
  transition: all 0.3s;
}
.button:hover p {
  color: #181818;
}
.stripe {
  display: block;
  width: 100%;
  max-width: var(--average-width);
  height: 1px;
  background-color: var(--micro-title);
}
.second_column {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: var(--average-width);
  align-items: center;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 60px;
}
.second_column div {
  display: flex;
  row-gap: 15px;
  column-gap: 35px;
  flex-wrap: wrap;
  justify-content: center;
}
.second_column p {
  display: flex;
  color: var(--title);
  font-family: "BuyanRegular";
  text-transform: uppercase;
  font-size: calc(var(--base-em) * var(--ms5));
  line-height: 100%;
  text-align: center;
}
.second_column a {
  text-decoration: none;
  color: var(--micro-title);
  font-family: "Onest";
  font-weight: 200;
  line-height: 150%;
  font-size: calc(var(--base-em) * var(--ms0));
  transition: all 0.3s;
}
.second_column a:link {
  text-decoration: none;
  color: var(--micro-title);
}
.second_column a:visited {
  text-decoration: none;
  color: var(--micro-title);
}
.second_column a:hover {
  text-decoration: none;
  color: var(--accent);
}
.second_column a:active {
  text-decoration: none;
  color: var(--accent);
}
.copyright {
  position: absolute;
  bottom: 1.5em;
  left: 1.5em;
}
.time {
  position: absolute;
  bottom: 1.5em;
  right: 1.5em;
}

@media (max-width: 640px) {
  footer {
    justify-content: center;
    padding: 3em 1.5em;
  }
  .footer{
    gap: 40px;
  }
  .first_column {
    align-items: center;
    text-align: center;
  }
  .button {
    width: 100%;
    padding: 10px 0px;
    overflow: hidden;
    font-size: calc(var(--base-em) / var(--ms1));
  }
  .copyright {
    width: 100%;
    position: unset;
    text-align: center;
  }
  .time {
    display: none;
  }
  .first_column_title {
    justify-content: center;
  }
  .first_column_buttons {
    justify-content: center;
  }
  .second_column {
    justify-content: center;
    flex-direction: column;
  }
  .second_column div {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 820px) {
  footer {
    align-items: flex-start;
  }
  .copyright {
    position: unset;
    padding: .5em 1.5em;
  }
  .time {
    position: unset;
    padding: .5em 1.5em;
  }
}
