.card {
  width: 100%;
  max-width: var(--maximal-width);
  background-color: var(--background-main);
  height: 500px;
  position: relative;
  padding-left: 3vw;
  padding-right: 3vw;
  box-shadow: 200px 0px 300px -160px #181818 inset;
  overflow: hidden;
}
.cardBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s;
}
.column {
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 370px;
  max-height: 500px;
  flex-direction: column;
  justify-content: center;
  gap: 14px;
  overflow: hidden;
  z-index: 1;
}
.column div {
  display: flex;
  width: fit-content;
  padding: 2px 5px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: #ffffff;
  color: #181818;
  font-family: "Onest";
  font-weight: 500;
  text-transform: uppercase;
  box-shadow: 1px 1px 1px #181818;
  z-index: 1;
}
.column h2 {
  color: #ffffff;
  font-weight: 700;
  text-shadow: 1px 1px 1px #181818;
  z-index: 1;
}
.column p {
  color: var(--card-text);
  text-shadow: 1px 1px 1px #181818;
  font-weight: 200;
  z-index: 1;
}
.card button {
  z-index: 1;
}
.button_not_ready {
  cursor: not-allowed;
  width: max-content;
  display: flex;
  padding: 8px 30px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: var(--background-main);
  font-size: calc(var(--base-em) * var(--ms0));
  text-align: center;
  font-family: "Onest";
  font-weight: 200;
  transition: all 0.3s;
  opacity: 0;
  gap: 5px;
  transform: translateY(-10px);
  z-index: 1;
}
.button_not_ready:hover {
  background-color: #ffffff;
  padding: 8px 30px;
}
.card:hover .button_not_ready {
  opacity: 1;
  transform: translateY(0);
}
@media (max-width: 640px) {
  .cardBackground {
    align-self: center;
    opacity: 0.8;
  }
 }
