.section_1,
.section_2,
.section_3 {
  width: 100%;
  max-width: var(--average-width);
  padding: 1.5em;
}
.section_2,
.section_3 {
  margin-top: 80px;
}
.section_1_images {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.about {
  width: 100%;
  height: auto;
}

.about1 {
  max-width: 30%;
  max-height: 30%;
  margin-top: auto;
  margin-bottom: 30px;
  z-index: 1;
}

.about2 {
  max-width: 35%;
  max-height: 35%;
  z-index: 2;
  transform: scale(1.05);
  box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.25);
}

.about3 {
  max-width: 35%;
  max-height: 35%;
  margin-top: 30px;
  margin-bottom: auto;
  z-index: 1;
}

.bio_information {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  column-gap: 20px;
  row-gap: 50px;
  margin-top: 50px;
  z-index: 2;
}
.micro_title {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--micro-title);
  font-family: "BuyanRegular";
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 100;
  font-size: calc(var(--base-em) * var(--ms2));
}
.description {
  color: var(--primary);
}
.description_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  align-items: flex-start;
  flex-shrink: 0;
}
.name {
  max-width: 200px;
}
.responsibilities {
  max-width: 200px;
}
.team {
  max-width: 390px;
}
.line {
  stroke: var(--primary);
  margin-bottom: 20px;
}
.stacks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  justify-content: space-between;
}
.stack {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 220px;
  align-items: center;
  justify-content: center;
  background-color: var(--background-container);
  gap: 20px;
}
.stack div {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: min-content;
}
.section_4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--background-container);
  height: min-content;
  width: 100%;
  padding-top: 5em;
  padding-bottom: 5em;
  overflow: hidden;
}
.section_4 div {
  max-width: var(--average-width);
  padding-left: 1.5em;
  padding-right: 1.5em;
}
.section_4 h3 {
  margin-top: 40px;
}
.rounded_div_wrap {
   width: 100%;
   top: 0;
   position: relative;
   height: 10vh;
   overflow: hidden;
}

.rounded_div {
   width: 150%;
   content: "";
   display: block;
   position: absolute;
   background: var(--background-container);
   height: 750%;
   left: 50%;
   border-radius: 50%;
   transform: translate(-50%, -86.666%);
   z-index: 1;
}
.footer_rounded_div {
   width: 100%;
   position: relative;
   height: 0;
   display: block;
   z-index: 2;
}

.footer_rounded_div .rounded_div_wrap {
   transform: translateY(-1px);
   will-change: height;
}

@media (max-width: 640px) {
  .bio_information {
    justify-content: center;
  }
  .description_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    align-items: center;
    text-align: center;
  }
  .name {
    max-width: 100%;
  }
  .responsibilities {
    max-width: 100%;
  }
  .team {
    max-width: 100%;
  }
  .stack {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 220px;
    align-items: center;
    justify-content: center;
    background-color: var(--background-container);
    gap: 20px;
  }
}
