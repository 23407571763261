.section_1 {
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  width: 100%;
  max-width: var(--average-width);
}
.section_1_cover {
  position: relative;
  top: 0px;
  left: 0px;
  width: 89vw;
  max-width: calc(var(--average-width) - 50px);
  height: auto;
  aspect-ratio: 1.2/1;
  max-height: 805px;
  box-shadow: 0px -200px 78px -71px var(--background-main) inset;
  overflow: hidden;
  transition: all 0.3s;
}
.section_1_cover img {
  position: absolute;
  top: 0px;
  width: 100%;
  max-width: calc(var(--average-width) - 50px);
  height: auto;
  object-fit: cover;
  z-index: -1;
  border-radius: 22px 22px 0 0;
}
.project_description {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  column-gap: 20px;
  row-gap: 50px;
  margin-top: -20px;
  z-index: 2;
}
.micro_title {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--micro-title);
  font-family: "BuyanRegular";
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 100;
  font-size: calc(var(--base-em) * var(--ms2));
}
.description {
  color: var(--primary);
}
.description_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  align-items: flex-start;
  flex-shrink: 0;
}
.project_name {
  max-width: 390px;
}
.responsibilities {
  max-width: 200px;
}
.team {
  max-width: 200px;
}
.summary {
  max-width: var(--average-width);
}
.section_2,
.section_5 {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0px;
  justify-content: center;
  align-items: center;
  background-color: var(--background-container);
  height: min-content;
  width: 100%;
  padding-top: 5em;
  padding-bottom: 5em;
  margin-top: 80px;
  overflow: hidden;
}
.section_2_mac {
  display: flex;
  width: 100%;
  max-width: var(--average-width);
  align-content: center;
  justify-content: center;
}
.section_2 img {
  width: calc(100%);
  z-index: 2;
}
.section_2_video {
  display: flex;
  position: absolute;
  align-content: center;
  justify-content: center;
  width: 100%;
  max-width: calc(var(--average-width));
}
.section_2_video video {
  width: 80%;
  margin-top: -5%;
}
.section_3,
.section_4,
.section_6 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: var(--average-width);
  width: 100%;
  padding: 1.5em;
  margin-top: 60px;
}
.section_4 img {
  margin-top: 60px;
}
.section_5 {
  position: relative;
  top: 0px;
  /* background-color: transparent; */
}
.section_5_img {
  width: 100%;
  max-width: var(--average-width);
  padding: 1.5em;
}
/* .section_5_bg{
    position: absolute;
    top: 0px;
    width: 110%;
    z-index: -1;
    opacity: 0.2;
    filter: blur(2px);
    object-fit:fill;
} */
.section_6 div {
  display: flex;
  flex-direction: row;
  row-gap: 20px;
  column-gap: 40px;
  justify-content: center;
  margin-top: 40px;
  max-width: 100%;
}
.section_6 div img {
  width: 100%;
  height: auto;
  max-width: 230px;
  margin-left: auto;
  margin-right: auto;
}
.section_7 {
  display: flex;
  flex-direction: column;
  background-color: var(--background-container);
  align-items: center;
  height: min-content;
  width: 100%;
  padding-top: 5em;
  padding-bottom: 5em;
  margin-top: 80px;
  overflow: hidden;
}
.section_7 div {
  width: 100%;
  max-width: var(--average-width);
  padding-left: 1.5em;
  padding-right: 1.5em;
}

@media (max-width: 640px) {
  .image {
    box-shadow: 0px -130px 40px -71px var(--background-main) inset;
  }
  .project_description {
    justify-content: center;
  }
  .description_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    align-items: center;
    text-align: center;
  }
  .project_name {
    max-width: 100%;
  }
  .responsibilities {
    max-width: 100%;
  }
  .team {
    max-width: 100%;
  }
  .summary {
    max-width: 100%;
  }
}
