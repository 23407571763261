header {
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: min-content;
  max-width: var(--maximal-width);
  z-index: 2;
  padding: 2em;
  column-gap: 85px;
  row-gap: 25px;
}
.logo {
  width: 45px;
  height: 45px;
  fill: var(--primary);
  transition: all 0.3s;
}
header nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 80px;
  justify-content: center;
}
nav a {
  font-family: "BuyanRegular";
  color: var(--title);
  letter-spacing: 1px;
  font-size: calc(var(--base-em) * var(--ms1));
  transition: all 0.3s;
}
nav a:link {
  text-decoration: none;
  color: var(--title);
}
nav a:visited {
  text-decoration: none;
  color: var(--title);
}
nav a:hover {
  text-decoration: none;
  color: var(--accent);
}
nav a:hover .logo, .logo:hover{
  fill: var(--accent);
}
nav a:active {
  text-decoration: none;
  color: var(--accent);
}

@media (max-width: 640px) {
  header {
    justify-content: center;
  }
}
