.preloader {
  height: 100vh;
  width: 100%;
  background: var(--background-main);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  overflow: hidden !important;
}

.texts_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 300px;
  overflow: hidden !important;
  opacity: 1;
  color: var(--primary);
}
.texts_container span{
    position: absolute;
}
.text{
  display: flex;
  color: var(--title);
  font-family: "BuyanRegular";
  text-transform: uppercase;
  font-weight: 100;
  font-size: calc(var(--base-em) * var(--ms6));
  line-height: 100%;
}
